.footer{
	/*height: 64px;*/
	position:fixed;
    bottom:0px;
    width:100%;
    border-top:1px solid #E7E7E7;
    background-color: #FFFFFF;
	color: #777777;
}
.footer a{
	color: #777777;
}
.coryright span{
	font-size:14px;
	padding: 0 20px 0 0;
}