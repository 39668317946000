.cell{
	-webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    margin-bottom: 24px;
    vertical-align: top;
}
.cell-title{
	position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}

.image-card:last-child{
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
}
.card{
    padding: 8px;
    border-top: 1px solid #d9d9d9;
    /*border-radius: 2px;*/
    /*height: 100%;*/
}
.test-card{
    padding: 8px;
    border: 1px solid #d9d9d9;
}
.card-title{
    font-size: 18px;
    font-weight: 500;
}
.card-tag{
    color: #999;
    margin-bottom: 5px;
}
.card-image-box{
    width: 128px;
    max-height: 128px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.card-image{
    width: 100%;
}
.card-file{
    width: 70%;
    height: auto;
    margin:auto;
}
.card-option{
    text-align: right;
}
.card-btn{
    padding: 4px 15px 4px 0px;
}
.card-close{
    position: absolute;
    right: 0px;
    top: -15px;
    font-size: 20px;
    cursor: pointer;
}
.card-type-show{
    width: 128px;
    position: absolute;
    top:44px;
    font-size: 14px;
    line-height: 24px;
    left: -5px;
    color: #FFF;
}

.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}

.table_list .ant-descriptions-item-label{
    width: 110px
}

.table_list2 .ant-descriptions-item-label{
    width: 150px
}
.table_list3 .ant-descriptions-item-label{
    width: 200px
}

.causal-box{
    border-top: 1px solid #f0f0f0;
    padding: 9px 0;
}
.causal-box:last-child{
    /*border-bottom: 1px solid #d9d9d9;*/
    border-bottom: 1px solid #f0f0f0;

}
.causal-box-title{
    font-weight: 900;
    font-size: 15px;
}
.causal-box .empty{
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
}
.required:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.ant-statistic-content {
  font-size: 20px;
  line-height: 28px;
}
.content {
  display: flex;
}
@media (max-width: 576px) {
  .content {
    display: block;
  }
}