body{
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',  'Noto Color Emoji';
	color: #131415;
	font-size: 14px;
	/*background-color: #FFFFFF;*/
}
.max-width{
	max-width: 1216px;
	min-width: 960px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 16px;
}
.text-left{
	text-align: left;
}
.text-right{
	text-align: right;
}
.text-center{
	text-align: center;
}
.button-box{
	margin-top: 10px
}
.button-box button{
	margin-right: 5px;
}

/* 分页距离右边的距离 */
.ant-pagination-options-size-changer.ant-select {
    margin-right: 0px;
}


.hand{
	cursor: pointer;
}

.main-color{
	/*color: #3B6DC5;*/
	font-weight: bold;
}

.select-before{
	width: 110px;
}

.btn-in-table{
	padding: 0 5px 0 5px;
	line-height:20px;
	height: 20px;
}


