.title{
	font-size: 33px;
    color: #3B6DC5;
    text-align: left;
    margin-bottom: 25px;
    margin-top: 8%;
    font-weight: bold;
    padding-left: 20px;
}

.login-center-box{
    margin-top:calc((100vh - 62px - 400px)/2);
}
.login-box{
	background-color: #FFF;
	padding: 15px 20px;
	border-radius: 4px;
}
.simple-block-header{
	padding: 20px;
	/*border-radius:5px 5px 0 0;*/
	font-size: 24px;
	text-align: center;
	color: #3B6DC5;
}
.simple-block-form{
	padding: 20px 30px 20px 30px;
	border-top: 1px solid #E8E8E8;
}

.login-form {
  max-width: 400px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.login-footer{
	position: fixed;
	bottom: 0px;
	height: 50px;
	line-height: 50px;
	width: 100%;
	text-align: center;	
}